.RegionTimeContainerDiv {
	/* border: solid 1px black; */
	box-sizing: border-box;
	display: flex;
	width: 350px;
	align-items: center;
}

.RegionTimeStateCountryDiv {
	flex: 1;
}

.RegionTimeStateGroupDiv {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.RegionTimeStateDiv {
	padding-right: 6px;
	font-size: 18px;
	font-weight: 900;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	max-width: 175px;
}

.RegionTimeTimeDiv {
	font-size: 15px;
	font-weight: 900;
}

@media screen and (max-width: 899px) {
	.RegionTimeStateDiv {
		max-width: 235px;
	}
	.RegionTimeTimeDiv {
		font-size: 16px;
	}
}


/* @media screen and (max-width: 380px) {
	.RegionTimeStateDiv {
		max-width: 54px;
	}
} */

.RegionTimeCountryDateDiv {
	padding-top: 3px;
	font-size: 12px;
}

.RegionTimeLineDiv {
	padding: 9px;
	align-self: flex-start;
	padding-top: 2px;
}

.RegionTimeDiffHomeDiv {
	opacity: 0.3;
	display: flex;
}

.RegionTimeDiffDiv {
	font-size: 15px;
	font-weight: 900;
}
