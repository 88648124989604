.AlertMessageContainerDiv {
	position: absolute;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
}

.AlertMessageAlert {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
