.DateTimePickerContainerDiv {
	position: relative;
}

.DateTimePickerParentDiv {
	display: flex;
}

/* Hide the calendar icon in most browsers */
input[type='datetime-local'] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	position: relative;
}

/* Hide the calendar icon in WebKit browsers */
input[type='datetime-local']::-webkit-calendar-picker-indicator {
	display: none;
}

input:focus {
	border: none;
	outline: none;
}

.DateTimePickerInput {
	border: none;
	padding-left: 9px;
	background-color: white;
    font-weight: 900;
}

.DateTimePickerInputDiv {
	padding: 9px;
    padding-left: 15px;
	padding-right: 0px;
	border: solid 1px #d7d7d7;
	width: fit-content;
}

.DateTimePickerCalendarTimeSelectorDiv {
	padding: 12px;
	justify-content: space-between;
	width: 345px;
	border: solid 1px #d7d7d7;
	margin-top: 6px;
	position: absolute;
	background-color: white;
	z-index: 1;
}

.DateTimePickerLabelDiv {
	font-size: 12px;
}

.DateTimePickerDateLabelDiv {
	font-weight: 800;
}

.DateTimePickerLabelContainerDiv {
	display: flex;
	justify-content: space-between;
	padding: 3px;
    padding-bottom: 6px;
    
}

input {
	color: black;
}
