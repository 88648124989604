.HeaderContainerDiv {
	padding: 18px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: white;
	width: 100vw;
    position: fixed;
    box-sizing: border-box;
    color: #14aaff;
	top: 0;
	z-index: 1;
	box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.1), -1px 1px 3px rgba(0, 0, 0, 0.1);

}

.HeaderLogoGroupDiv {
	display: flex;
	align-items: center;
}

.HeaderTitleDiv {
	padding-left: 9px;
	font-weight: 900;
}

.HeaderTextDiv {
	font-size: 16px;
}