@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
	margin: 0;
	/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif; */
	font-family: 'Inter';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.CursorPointer {
	cursor: pointer;
}

input {
	color: black;
	font-family: 'Inter';
	font-size: 15px;
}

select {
	font-family: 'Inter';
}

@media screen and (max-width: 899px) {
	input {
		font-size: 16px;
	}

	select {
		font-size: 16px;
	}
}
