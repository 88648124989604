.GroupButtonContainerDiv {
	border: solid 1px #d7d7d7;
    display: flex;
    width: 90px;
    height: 30px;
    box-sizing: border-box;
}

.GroupButtonInnerDiv {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.GroupButtonLineDiv {
    background: #d7d7d7;
    height: 100%;
    width: 1px
}

.GroupButtonInnerContainerDiv{
    display: flex;
    flex: 1;
}