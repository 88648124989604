.WordButtonContainerDiv {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	padding: 6px;
	color: white;
	width: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background-color: #14aaff;
	font-size: 15px;
}

.WordButtonContainerDiv:hover {
	background: #14aaff;
	color: white;
}
