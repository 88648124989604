.FooterContainerDiv {
	width: 100%;
	box-sizing: border-box;
	background-color: #14aaff;
	padding: 30px;
	color: white;
	display: grid;
	align-items: center;
}

.FooterLogoDiv {
	display: flex;
	align-items: center;
	justify-content: center;
}

.FooterTitleDiv {
	padding-left: 9px;
	font-size: 16px;
	font-weight: 600;
}

.FooterTextDiv {
	font-size: 12px;
}

.FooterEmailDiv {
	text-align: right;
}

@media screen and (max-width: 899px) {
	.FooterRightsDiv {
		text-align: center;
        padding-top: 12px;
        padding-bottom: 30px;
	}
	.FooterEmailDiv {
		text-align: center;
        padding-top: 30px;
        padding-bottom: 12px;
	}
}

@media screen and (min-width: 900px) {
	.FooterContainerDiv {
		grid-template-columns: 1fr 1fr 1fr;
	}
	.FooterLogoDiv {
		justify-content: center;
	}
}
