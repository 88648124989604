.PlaceSearcherContainerDiv {
	display: flex;
	align-items: center;
}

.PlaceSearcherInputDiv {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	border: solid 1px #14aaff;
	padding: 9px;
	padding-right: 6px;
	width: fit-content;
	display: flex;
	align-items: center;
	height: 36px;
	font-size: 15px;
	flex: 1;
	position: relative
}

.PlaceSearcherInput {
	outline: none;
	border: none;
	width: 100%;
}

.PlaceSearcherDataList {
	height: 600px;
	position: absolute;
	overflow-x: hidden;
	overflow-y: auto;
	left: 0;
}

.PlaceSearcherList {
	position: absolute !important;
	top: 39px;
	left: 0;
	z-index: 1;
	background-color: white;
	border: solid 1px #d7d7d7;
	padding: 0 !important;
}

.PlaceSearcherListItem {
	padding: 0 !important;
}

.PlaceSearcherListItemButton {
	border-bottom: solid 1px #d7d7d7 !important;
	padding-top: 12px !important;
	padding-bottom: 12px !important;
	display: block !important;
}

.PlaceSearcherListItemDiv {
	font-weight: 900;
}

.PlaceSearcherListItemDiv2 {
	padding-top: 3px;
	font-size: 12px;
}