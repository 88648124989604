.RegionTimeGroupIconsDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-right: 6px;
}

.RegionTimeGroupContainerDiv {
	display: flex;
}

.RegionTimeGroupCardDiv {
	display: grid;
    align-items: center;
	flex: 1;
	background-color: white;
	box-shadow: 1px -1px 3px rgba(0, 0, 0, 0.1), -1px 1px 3px rgba(0, 0, 0, 0.1);
    padding: 12px;
}
.RegionTimeGroupGridItem1 {
    padding-left: 3px;
    padding-right: 15px;
}

@media screen and (min-width: 900px) {
	.RegionTimeGroupCardDiv {
		grid-template-columns: 2fr 1fr 1fr;
        justify-content: space-between;

	}

	.RegionTimeGroupGridItem2 {
		margin-right: 12px;
	}
}

@media screen and (max-width: 899px) {
	.RegionTimeGroupGridItem2 {
		margin-top: 12px;
	}

	.RegionTimeGroupGridItem3 {
		margin-top: 6px;
	}
    .RegionTimeGroupGridItem1 {
        padding-right: 3px;
    }

    .RegionTimeGroupIconsDiv {
        justify-content: flex-start;
    }
}
