.TimeConverterTemplateDetailsGroupDiv {
	/* padding: 45px; */
	padding-top: 45px;
	display: grid;
	font-size: 12px;
}

.TimeConverterTemplateTimeRangeGroupDiv {
	display: flex;
	width: 508px;
	align-items: flex-end;
	padding-left: 12px;
	padding-right: 12px;
}

.TimeConverterTemplateTimeRangeGroupDiv2 {
	display: none;
}

.TimeConverterTemplateTimeRangeDiv {
	display: flex;
	align-items: center;
	padding-left: 12px;
	padding-right: 12px;
}

.TimeConverterTemplateBodyDiv {
	padding: 45px;
	padding-left: 15px;
	padding-top: 60px;
	max-width: 850px;
	box-sizing: border-box;
}

.TimeConverterTemplateH {
	font-size: 15px;
	margin-top: 0;
}

.TimeConverterTemplateProgressDiv {
	margin-bottom: 9px;
}

@media screen and (max-width: 899px) {
	.TimeConverterTemplateBodyDiv {
		max-width: 375px;
	}

	.TimeConverterTemplateTimeRangeGroupDiv {
		display: none;
	}

	.TimeConverterTemplateTimeRangeGroupDiv2 {
		display: flex;
		padding-left: 30px;
		padding-bottom: 9px;
		align-items: center;
	}

	.TimeConverterTemplateTimeRangeLabelDiv {
		font-size: 15px;
		font-weight: 600;
		flex: 1;
	}
	.TimeConverterTemplateTimeRangeDiv {
		padding-right: 0;
	}

	.TimeConverterTemplateDetailsGroupDiv {
		font-size: 16px;
	}

	.TimeConverterTemplateH {
		font-size: 16px;
	}

	.TimeConverterTemplateImg {
		margin: 0px 0px 0px -60px;
		height: 100%;
	}
}

@media screen and (min-width: 900px) {
	.TimeConverterTemplateDetailsGroupDiv {
		grid-template-columns: 1fr 1fr 1fr;
	}

	.TimeConverterTemplateImg {
		margin: -60px 0px 0px 0px;
		width: 100%;
	}
}

.TimeConverterBodyFooterDiv {
	overflow: auto;
	height: calc(100vh - 60px);
	margin-top: 66px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	overflow-x: hidden;
}

.TimeConverterTemplatePlaceTimeDiv {
	padding-left: 30px;
	display: flex;
}

.TimeConverterTemplateAdvDiv {
	margin-left: 30px;
	margin-top: 45px;
	margin-bottom: 45px;
	height: 150px;
	background-color: lightgray;
	overflow: hidden;
}

.TimeConverterTemplateDetailDiv {
	padding-left: 30px;
	padding-bottom: 30px;
}

.TimeConverterTemplateLeftRangeDiv {
	height: 50%;
	width: 100%;
	border-left: solid 1px #d7d7d7;
	border-top: solid 1px #d7d7d7;
}

.TimeConverterTemplateRightRangeDiv {
	height: 50%;
	width: 100%;
	border-right: solid 1px #d7d7d7;
	border-top: solid 1px #d7d7d7;
}

.TimeConverterTemplateRegionTimeDiv {
	padding-top: 12px;
}
