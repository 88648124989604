.TexfieldContainerDiv {
	box-sizing: border-box;
	border: solid #d7d7d7 1px;
    padding: 6px;
    width: fit-content;
}

.TextfieldInput {
	width: -webkit-fill-available;
    border: none;
	outline: none;
	height: -webkit-fill-available;
}

.TextfieldInput:focus {
	border: none;
	outline: none;
}
