.iconButton {
	cursor: pointer;
	padding: 0px;
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 0;
	background-color: white;
	box-sizing: border-box;
}
