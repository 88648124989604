.TimeSelectorContainerDiv {
	display: flex;
	width: 90px;
	height: fit-content;
	position: relative;
	margin-top: 9px;
}

.TimeSelectorGroupDiv {
	flex: 1;
	height: 180px;
	overflow-y: scroll;
}

.TimeSelectorGroupDiv::-webkit-scrollbar {
	display: none;
}

.TimeSelectorLabelDiv {
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.TimeSelectorSelectionDiv {
	width: 90px;
	height: 30px;
	background-color: #e9e8e8;
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
	margin: auto 0;
}

.TimeSelectorOpacityDiv {
	width: 90px;
	height: 75px;
	position: absolute;
	pointer-events: none;
}

.TimeSelectorOpacityDiv1 {
	background: linear-gradient(
		to bottom,
		rgba(255, 255, 255, 1),
		rgba(255, 255, 255, 0.6)
	);
	top: 0;
}

.TimeSelectorOpacityDiv2 {
	background: linear-gradient(
		to bottom,
		rgba(255, 255, 255, 0.6),
		rgba(255, 255, 255, 1)
	);
	bottom: 0;
}
